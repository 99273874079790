import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  return (
    <>
      <Header />
      <section className="w3l-main-slider  w3-banner jarallax" id="home">
        <video className="img-fluid" autoPlay muted loop style={{ zIndex: "" }}>
          <source src="assets/images/video.mp4" type="video/mp4" />
        </video>
        {/* main-slider */}
        <div className="companies20-content" style={{ width: "100%" }}>
          <div className="owl-one ">
            <div className="item text-center">
              <li>
                <div
                  className="slider-info banner-view bg bg2"
                  data-selector=".bg.bg2"
                >
                  <div className="banner-info">
                    <div className="container">
                      <div className="banner-info-bg mr-auto">
                        <h5>
                          Welcome
                          <br />
                          To {companyname}
                        </h5>
                        <p>
                          Our company is dedicated to seamlessly connecting
                          businesses worldwide, fostering growth and prosperity
                          through efficient and reliable freight forwarding
                          solutions.
                        </p>
                        <Link
                          className="btn btn-theme2 mt-lg-5 mt-4"
                          to="/About"
                        >
                          Learn More About Us
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </div>
          </div>
        </div>
        {/* script for */}
        {/* //script */}
        {/* /main-slider */}
      </section>

      <section className="w3l-teams-15">
        <div className="team-single-main editContent">
          <div className="container">
            <div className="row">
              <div className="column2 col-lg-6">
                <img
                  src="assets/images/b1.jpg"
                  alt="product"
                  className="img-responsive "
                />
              </div>
              <div className="nature-row  coloum4 col-lg-6">
                <h4 className="small-title">About {companyname}</h4>
                <h3></h3>
                <p className="para editContent text ">
                  We stand out as distinguished Freight forwarding Service
                  Providers, originating from India. Our unwavering commitment
                  and reliable solutions have propelled us to a prestigious
                  standing. Utilizing our extensive and well-established
                  network, we offer seamless services including Air and Sea
                  Freight Forwarding, Warehousing Storage, and Customs Clearing.
                  <br />
                  Recognizing the unique needs of our clients, we prioritize
                  personalized consultations to fully comprehend their distinct
                  requirements. Our approach shuns the one-size-fits-all
                  mentality, ensuring tailored solutions that cater to your
                  specific demands. With a specialization in both Imports and
                  Exports via SEA and AIR, we ensure competitive rates and
                  service excellence, consistently exceeding our valued clients'
                  expectations.
                </p>
                <Link to="/About" className="action-button btn mt-lg-5 mt-4">
                  Read more
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="w3l-stats">
        <div className="main-w3 ">
          <div className="container">
            <div className="right-side">
              <h3 className="title-big">
                Our Trading Services
              </h3>
              <p className="mt-4 para">
                At the crossroads of international trade, {companyname} emerges as a
                distinguished trader and customs broker, a beacon guiding the
                seamless movement of goods across the global stage. With an
                expertise honed through time and a global network woven
                meticulously, we stand as custodians of cost-effective,
                efficient, and unwaveringly reliable solutions for our esteemed
                clientele.
                <br />
                Our expansive network of partners spans continents, a testament
                to our commitment to craft a world without borders, where goods
                traverse oceans and skies with a precision that echoes
                efficiency. This consortium of collaborations forms the bedrock
                upon which our promise of seamless solutions rests, ensuring
                that each customer journey is not just a transaction but a
                symphony of fulfillment.
              </p>
              <Link to="/Trading" className="action-button btn mt-lg-5 mt-4">
                  View All Trading Services
                </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="w3l-covers-18">
        <div className="covers-main editContent">
          <div className="container">
            <div className="main-titles-head ">
              <h3 className="header-name">Our Services</h3>
              <p className="tiltle-para editContent ">
                Unlock boundless possibilities with our seamless shipping
                solutions.
              </p>
            </div>
            <div className="gallery-image row">
              <div className="col-lg-4 col-md-6">
                <div className="img-box">
                  <img
                    src="assets/images/air.jpg"
                    style={{ height: "220px" }}
                    alt="product"
                    className="img-responsive "
                  />
                  <h5 className="my-2">
                    <Link to="/Air">Air Freight</Link>
                  </h5>

                  <div className="top-gap">
                    <h5>Learn More</h5>
                    <Link to="/Air" className="icon text-center">
                      <span className="fa fa-chevron-right" />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="img-box">
                  <img
                    src="assets/images/ocean.jpg"
                    style={{ height: "220px" }}
                    alt="product"
                    className="img-responsive "
                  />
                  <h5 className="my-2">
                    <Link to="/Sea">Sea Freight</Link>
                  </h5>

                  <div className="top-gap">
                    <h5>Learn More</h5>
                    <Link to="/Sea" className="icon text-center">
                      <span className="fa fa-chevron-right" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="img-box">
                  <img
                    src="assets/images/project.webp"
                    style={{ height: "220px" }}
                    alt="product"
                    className="img-responsive "
                  />
                  <h5 className="my-2">
                    <Link to="/Project">Project Logistics</Link>
                  </h5>

                  <div className="top-gap">
                    <h5>Learn More</h5>
                    <Link to="/Project" className="icon text-center">
                      <span className="fa fa-chevron-right" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="img-box">
                  <img
                    src="assets/images/supply.webp"
                    style={{ height: "220px" }}
                    alt="product"
                    className="img-responsive "
                  />
                  <h5 className="my-2">
                    <Link to="/Supply">Supply Chain & Management</Link>
                  </h5>

                  <div className="top-gap">
                    <h5>Learn More</h5>
                    <Link to="/Supply" className="icon text-center">
                      <span className="fa fa-chevron-right" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="img-box">
                  <img
                    src="assets/images/customs.jpg"
                    style={{ height: "220px" }}
                    alt="product"
                    className="img-responsive "
                  />
                  <h5 className="my-2">
                    <Link to="/Customs">Customs Brokerage & Compliance</Link>
                  </h5>

                  <div className="top-gap">
                    <h5>Learn More</h5>
                    <Link to="/Imports" className="icon text-center">
                      <span className="fa fa-chevron-right" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="img-box">
                  <img
                    src="assets/images/imports.jpg"
                    style={{ height: "220px" }}
                    alt="product"
                    className="img-responsive "
                  />
                  <h5 className="my-2">
                    <Link to="/Imports">Imports & Exports</Link>
                  </h5>

                  <div className="top-gap">
                    <h5>Learn More</h5>
                    <Link to="/Air" className="icon text-center">
                      <span className="fa fa-chevron-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
