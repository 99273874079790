import { useState } from "react";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Contact() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [result, setResult] = useState("");

  const handleFnameChange = (e) => {
    setFname(e.target.value);
  };
  const handlelnameChange = (e) => {
    setLname(e.target.value);
  };
  const handleemailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlesubjectChange = (e) => {
    setSubject(e.target.value);
  };
  const handlemessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = $(e.target);
    $.ajax({
      type: "POST",
      url: form.attr("action"),
      data: form.serialize(),
      success(data) {
        setResult(data);
      },
    });
  };
  return (
    <>
      <Header />
      <section className="w3l-inner-banner-main">
          <div className="about-inner contact">
            <div className="breadcrumbs-sub">
              <ul className="breadcrumbs-custom-path">
                <li className="right-side propClone"><Link to="/Home" className="editContent">Home <span className="fa fa-angle-right" aria-hidden="true" /></Link> <p /></li>
                <li className="active editContent">
                  Contact</li>
              </ul>
            </div>
          </div>
        </section>
        {/* breadcrumbs //*/}
        <section className="w3l-contact-info-main" id="contact">
          <div className="contact-sec	editContent">
            <div className="container">
              <div className="map-content-9 mb-5 ">
                <div className="text-center m-4">
                  <h3>
                  Unlock the potential of seamless shipping solutions – fill up the contact form now!
                  </h3>
                </div>
              <form
                action="/php/thankyou-contact.php "
                method="post"
                onSubmit={(event) => handleSubmit(event)}
              >
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        onChange={(event) => handleFnameChange(event)}
                        type="text"
                        className
                        id="fname"
                        name="fname"
                        value={fname}
                        placeholder="Enter your first name"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                      />
                      <span />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        onChange={(event) => handlelnameChange(event)}
                        type="text"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="lname"
                        name="lname"
                        value={lname}
                        placeholder="Enter your last name"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input
                        onChange={(event) => handleemailChange(event)}
                        type="email"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="email"
                        name="email"
                        value={email}
                        placeholder="Enter your Email"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input
                        onChange={(event) => handlesubjectChange(event)}
                        type="text"
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="subject"
                        name="subject"
                        value={subject}
                        placeholder="Enter subject"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <textarea
                        style={{
                          width: "100%",
                          padding: "10px",
                          margin: "6px 0",
                        }}
                        id="message"
                        name="message"
                        rows={1}
                        placeholder="Enter Message"
                        defaultValue={""}
                        value={message}
                        onChange={(event) => handlemessageChange(event)}
                      />
                    </div>
                  </div>
                  <div className="submit-w3l-button text-lg-right">
                    <button
                      name="submit"
                      className="btn btn-contact"
                      type="submit"
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
              <h1>{result}</h1>
              </div>
              <div className="d-grid contact-view">
                <div className="cont-details">
                  <h3 className="sub-title">Quick Contact</h3> 
                  <p className="para mt-3 mb-4">have Any Query? Contact Us..</p>
                  <div className="cont-top">
                    <div className="cont-left text-center">
                      <span className="fa fa-phone text-secondary" />
                    </div>
                    <div className="cont-right">
                      <p className="para"><a href="tel:{companynumber}">{companynumber}</a></p>
                    </div>
                  </div>
                  <div className="cont-top margin-up">
                    <div className="cont-left text-center">
                      <span className="fa fa-envelope-o text-secondary" />
                    </div>
                    <div className="cont-right">
                      <p className="para">{companyemail}</p>
                    </div>
                  </div>
                  <div className="cont-top margin-up">
                    <div className="cont-left text-center">
                      <span className="fa fa-map-marker text-secondary" />
                    </div>
                    <div className="cont-right">
                      <p className="para"> {companyaddress}</p>
                    </div>
                  </div>
                </div>
                <div className="map-iframe ">
                <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d60275.69726106052!2d72.9344066!3d19.2287584!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b92abc14627f%3A0x67321c4ebf19795e!2sLake%20City%20Mall!5e0!3m2!1sen!2sin!4v1690354023087!5m2!1sen!2sin"
          width="100%"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>  </div>
              </div>
            </div>
          </div>
        </section>
      <Footer />
    </>
  );
}
