import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />
      <section className="w3l-inner-banner-main">
        <div className="about-inner contact">
          <div className="breadcrumbs-sub">
            <ul className="breadcrumbs-custom-path">
              <li className="right-side propClone">
                <Link to="/Home" className="editContent">
                  Home <span className="fa fa-angle-right" aria-hidden="true" />
                </Link>{" "}
                <p />
              </li>
              <li className="active editContent">Sea Freight</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-blog-single">
        <div className="sec-padding editContent">
          <div className="container">
            <div className="blog-grid">
              <div className="blog-right">
                {/* latest posts */}
                <div className="latest-posts blog-right-single">
                  <div className="comments-grid-right ">
                    <h5 className="editContent">Our Services</h5>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Air">
                        <img
                          src="assets/images/air.jpg"
                          className="img-responsive"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="single-blog-left">
                      <h4>
                        <Link to="/Air" className="editContent">
                          Air Freight
                        </Link>
                      </h4>
                    </div>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Sea">
                        <img
                          src="assets/images/ocean.jpg"
                          className="img-responsive"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="single-blog-left">
                      <h4>
                        <Link to="/Sea" className="editContent">
                          Sea Freight
                        </Link>
                      </h4>
                    </div>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Project">
                        <img
                          src="assets/images/project.webp"
                          className="img-responsive"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="single-blog-left">
                      <h4>
                        <Link to="/Project" className="editContent">
                          Project Logistics
                        </Link>
                      </h4>
                    </div>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Supply">
                        <img
                          src="assets/images/supply.webp"
                          className="img-responsive"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="single-blog-left">
                      <h4>
                        <Link to="/Supply" className="editContent">
                          Supply Chain Management
                        </Link>
                      </h4>
                    </div>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Customs">
                        <img
                          src="assets/images/customs.jpg"
                          className="img-responsive"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="single-blog-left">
                      <h4>
                        <Link to="/Customs" className="editContent">
                          Customs Brokerage & Compliance
                        </Link>
                      </h4>
                    </div>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Imports">
                        <img
                          src="assets/images/imports.jpg"
                          className="img-responsive"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="single-blog-left">
                      <h4>
                        <Link to="/Imports" className="editContent">
                          Imports & Export
                        </Link>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              {/*blog-grid*/}
              <div className="blog-left blog-right-single">
                <div className="blog-grid-one">
                  <img
                    src="assets/images/ocean.jpg"
                    alt=" "
                    className="img-responsive"
                  />
                  <h4>
                    <Link className="editContent"> Sea Freight</Link>
                  </h4>
                  <p className="para editContent">
                    No matter the nature of your cargo, our comprehensive sea
                    freight forwarding services stand ready to facilitate
                    seamless transportation to and from any destination,
                    ensuring not only the secure conveyance of your goods but
                    also a punctual arrival. Leveraging the extensive expanse of
                    our global partner network, we orchestrate a symphony of
                    logistics that extends across oceans and continents,
                    underscoring our unwavering commitment to deliver
                    excellence.
                    <br />
                    At the heart of our sea freight prowess lies a strategic
                    container consolidation apparatus that infuses your shipping
                    experience with an unparalleled degree of flexibility and
                    reliability in transit times. Through the art of
                    consolidation, we harmonize diverse consignments into a
                    harmonious whole, streamlining the movement of cargo and in
                    turn, assuring that the rhythm of international sea freight
                    remains harmonious and uninterrupted.
                    <br />
                    While our foundation rests on the bedrock of sea freight
                    proficiency, it's essential to acknowledge our adeptness in
                    all modes of transportation. Beyond the vast oceanic
                    expanses, {companyname} unfurls its wings as an air freight
                    specialist, further enriching the repertoire of options at
                    your disposal. The skies, much like the seas, are traversed
                    with precision and care, ensuring that your cargo journeys
                    on the most suitable path, no matter the altitude.
                    <br />
                    For those yearning for a deeper understanding or seeking to
                    explore the dimensions of our services, we extend an
                    invitation to reach out. At {companyname}, we don't merely
                    offer services; we weave a tapestry of connectivity,
                    bridging aspirations and destinations with a commitment that
                    transcends the transactional. The beacon of our customer
                    service awaits your inquiry, poised to illuminate your path
                    through the labyrinth of logistics.
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
