import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Services() {
  return (
    <>
      <Header />

     {/* Page Header Start */}
     <section className="w3l-inner-banner-main">
          <div className="about-inner contact">
            <div className="breadcrumbs-sub">
              <ul className="breadcrumbs-custom-path">
                <li className="right-side propClone"><Link to="/Home" className="editContent">Home <span className="fa fa-angle-right" aria-hidden="true" /></Link> <p /></li>
                <li className="active editContent">
              Services</li>
              </ul>
            </div>
          </div>
        </section>

        <section className="w3l-covers-18">
        <div className="covers-main editContent">
          <div className="container">
            <div className="main-titles-head ">
              <h3 className="header-name">Our Services</h3>
              <p className="tiltle-para editContent ">
                Unlock boundless possibilities with our seamless shipping
                solutions.
              </p>
            </div>
            <div className="gallery-image row">
              <div className="col-lg-4 col-md-6">
                <div className="img-box">
                  <img
                    src="assets/images/air.jpg"
                    style={{ height: "220px" }}
                    alt="product"
                    className="img-responsive "
                  />
                  <h5 className="my-2">
                    <Link to="/Air">Air Freight</Link>
                  </h5>

                  <div className="top-gap">
                    <h5>Learn More</h5>
                    <Link to="/Air" className="icon text-center">
                      <span className="fa fa-chevron-right" />
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="img-box">
                  <img
                    src="assets/images/ocean.jpg"
                    style={{ height: "220px" }}
                    alt="product"
                    className="img-responsive "
                  />
                  <h5 className="my-2">
                    <Link to="/Sea">Sea Freight</Link>
                  </h5>

                  <div className="top-gap">
                    <h5>Learn More</h5>
                    <Link to="/Sea" className="icon text-center">
                      <span className="fa fa-chevron-right" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="img-box">
                  <img
                    src="assets/images/project.webp"
                    style={{ height: "220px" }}
                    alt="product"
                    className="img-responsive "
                  />
                  <h5 className="my-2">
                    <Link to="/Project">Project Logistics</Link>
                  </h5>

                  <div className="top-gap">
                    <h5>Learn More</h5>
                    <Link to="/Project" className="icon text-center">
                      <span className="fa fa-chevron-right" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="img-box">
                  <img
                    src="assets/images/supply.webp"
                    style={{ height: "220px" }}
                    alt="product"
                    className="img-responsive "
                  />
                  <h5 className="my-2">
                    <Link to="/Supply">Supply Chain & Management</Link>
                  </h5>

                  <div className="top-gap">
                    <h5>Learn More</h5>
                    <Link to="/Supply" className="icon text-center">
                      <span className="fa fa-chevron-right" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="img-box">
                  <img
                    src="assets/images/customs.jpg"
                    style={{ height: "220px" }}
                    alt="product"
                    className="img-responsive "
                  />
                  <h5 className="my-2">
                    <Link to="/Customs">Customs Brokerage & Compliance</Link>
                  </h5>

                  <div className="top-gap">
                    <h5>Learn More</h5>
                    <Link to="/Imports" className="icon text-center">
                      <span className="fa fa-chevron-right" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="img-box">
                  <img
                    src="assets/images/imports.jpg"
                    style={{ height: "220px" }}
                    alt="product"
                    className="img-responsive "
                  />
                  <h5 className="my-2">
                    <Link to="/Imports">Imports & Exports</Link>
                  </h5>

                  <div className="top-gap">
                    <h5>Learn More</h5>
                    <Link to="/Air" className="icon text-center">
                      <span className="fa fa-chevron-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
