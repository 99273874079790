import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function About() {
  return (
    <>
      <Header />
      <section className="w3l-inner-banner-main">
          <div className="about-inner contact">
            <div className="breadcrumbs-sub">
              <ul className="breadcrumbs-custom-path">
                <li className="right-side propClone"><Link to="/Home" className="editContent">Home <span className="fa fa-angle-right" aria-hidden="true" /></Link> <p /></li>
                <li className="active editContent">
              About Us</li>
              </ul>
            </div>
          </div>
        </section>
      <section className="w3l-teams-15">
        <div className="team-single-main editContent">
          <div className="container">
            <div className="row">
              <div className="column2 col-lg-6">
                <img
                  src="assets/images/b1.jpg"
                  alt="product"
                  className="img-responsive "
                />
              </div>
              <div className="nature-row  coloum4 col-lg-6">
                <h4 className="small-title">About {companyname}</h4>
                <h3></h3>
                <p className="para editContent text ">
                  We stand out as distinguished Freight forwarding Service
                  Providers, originating from India. Our unwavering commitment
                  and reliable solutions have propelled us to a prestigious
                  standing. Utilizing our extensive and well-established
                  network, we offer seamless services including Air and Sea
                  Freight Forwarding, Warehousing Storage, and Customs Clearing.
                  <br />
                  Recognizing the unique needs of our clients, we prioritize
                  personalized consultations to fully comprehend their distinct
                  requirements. Our approach shuns the one-size-fits-all
                  mentality, ensuring tailored solutions that cater to your
                  specific demands. With a specialization in both Imports and
                  Exports via SEA and AIR, we ensure competitive rates and
                  service excellence, consistently exceeding our valued clients'
                  expectations.
                </p>
                
              </div>
            </div>
            <p className="para editContent text mt-4">What sets us apart is our dedicated, experienced, and knowledgeable team across all offices, assuring meticulous compliance with stringent customs, excise, and governmental regulations associated with international trade.
</p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
