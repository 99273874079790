import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />
      <section className="w3l-inner-banner-main">
          <div className="about-inner contact">
            <div className="breadcrumbs-sub">
              <ul className="breadcrumbs-custom-path">
                <li className="right-side propClone"><Link to="/Home" className="editContent">Home <span className="fa fa-angle-right" aria-hidden="true" /></Link> <p /></li>
                <li className="active editContent">
                Project Logistics</li>
              </ul>
            </div>
          </div>
        </section>
      <section className="w3l-blog-single">
        <div className="sec-padding editContent">
          <div className="container">
            <div className="blog-grid">
              <div className="blog-right">
                {/* latest posts */}
                <div className="latest-posts blog-right-single">
                  <div className="comments-grid-right ">
                    <h5 className="editContent">Our Services</h5>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Air">
                        <img src="assets/images/air.jpg" className="img-responsive" alt="" /></Link>
                    </div>
                    <div className="single-blog-left">
                      
                      <h4><Link to="/Air" className="editContent">Air Freight</Link>
                      </h4>
                    </div>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Sea">
                        <img src="assets/images/ocean.jpg" className="img-responsive" alt="" /></Link>
                    </div>
                    <div className="single-blog-left">
                      
                      <h4><Link to="/Sea" className="editContent">Sea Freight</Link>
                      </h4>
                    </div>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Project">
                        <img src="assets/images/project.webp" className="img-responsive" alt="" /></Link>
                    </div>
                    <div className="single-blog-left">
                      
                      <h4><Link to="/Project" className="editContent">Project Logistics</Link>
                      </h4>
                    </div>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Supply">
                        <img src="assets/images/supply.webp" className="img-responsive" alt="" /></Link>
                    </div>
                    <div className="single-blog-left">
                      
                      <h4><Link to="/Supply" className="editContent">Supply Chain Management</Link>
                      </h4>
                    </div>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Customs">
                        <img src="assets/images/customs.jpg" className="img-responsive" alt="" /></Link>
                    </div>
                    <div className="single-blog-left">
                      
                      <h4><Link to="/Customs" className="editContent">Customs Brokerage & Compliance</Link>
                      </h4>
                    </div>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Imports">
                        <img src="assets/images/imports.jpg" className="img-responsive" alt="" /></Link>
                    </div>
                    <div className="single-blog-left">
                      
                      <h4><Link to="/Imports" className="editContent">Imports & Export</Link>
                      </h4>
                    </div>
                  </div>
                </div>
                
              </div>
              {/*blog-grid*/}
              <div className="blog-left blog-right-single">
                <div className="blog-grid-one">
                  <img src="assets/images/project.webp" alt=" " className="img-responsive" />
                  <h4><Link className="editContent">  Project Logistics
                    </Link></h4>
                  <p className="para editContent">
                  Within the dynamic landscape of material and equipment movement, {companyname} boasts a team of seasoned professionals whose wealth of experience and hands-on expertise stands as a testament to their unparalleled capabilities. With a track record that resonates with excellence, this team has adeptly orchestrated forwarding and logistics services across an array of sectors, each marked by its unique demands and intricacies. Noteworthy among these are the domains of oil & gas, minerals, energy, construction, and major infrastructure - arenas where precision and reliability are paramount from the very birth of a project to its triumphant culmination.
<br/>
From the very inception of a venture, {companyname}'s adept team steps onto the stage, ready to choreograph a symphony of movement that is as strategic as it is meticulous. The world of oversized equipment and substantial materials demands an orchestra of expertise, where every note is played with utmost precision. {companyname}'s virtuoso ensemble has proven time and again that they possess the mettle to navigate this intricate composition, ensuring that the cadence of movement reverberates seamlessly from the earliest stages of a project.
<br/>
The sectors of oil & gas, minerals, energy, construction, and major infrastructure encompass a realm where delays and miscalculations are costly endeavors. Yet, within this high-stakes arena, {companyname}'s team thrives. With their hands firmly on the pulse of logistics, they sculpt strategies that not only conquer the challenges inherent to each sector but do so with an astuteness that is second nature. It's more than just moving materials; it's a meticulous dance that ushers equipment and resources towards the grand finale - the successful completion of major undertakings.
<br/>
As the embodiment of {companyname}'s commitment to excellence, this team orchestrates an intricate ballet where timelines, resources, and destinations harmonize to create a seamless crescendo. Their track record isn't just a collection of achievements; it's a symposium of triumphs that underscores their ability to execute, deliver, and transcend expectations. The sectors they traverse demand nothing short of the extraordinary, and it is precisely this caliber of performance that {companyname}'s team delivers, unswervingly, from inception to completion.

                  
                  </p>
                </div>
               
              </div>
            </div>
           
           
          </div>
        </div></section>
      <Footer />
    </>
  );
}