import { Outlet, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  return (
    <>
      <section className=" w3l-header-4 header-sticky">
        {/*header*/}
        <header id="site-header" className="fixed-top">
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-dark stroke">
              <h1>
                <Link className="navbar-brand" to="/Home">
                  <img
                    src="assets/images/logo.png "
                    style={{ height: "70px" }}
                  ></img>
                </Link>
              </h1>
              {/* if logo is image enable this   
			<Link class="navbar-brand" to="#index.html">
				<img src="image-path" alt="Your logo" title="Your logo" style="height:35px;" />
			</Link> */}
              <button
                className="navbar-toggler  collapsed bg-gradient"
                type="button"
                data-toggle="collapse"
                data-target="#navbarTogglerDemo02"
                aria-controls="navbarTogglerDemo02"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon fa icon-expand fa-bars" />
                <span className="navbar-toggler-icon fa icon-close fa-times" />
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarTogglerDemo02"
              >
                <ul className="navbar-nav ml-lg-5">
                  <li className="nav-item">
                    <Link className="nav-link" to="/Home">
                      Home <span className="sr-only">(current)</span>
                    </Link>
                  </li>
                  <li className="nav-item @@about__active">
                    <Link className="nav-link" to="/About">
                      About Company
                    </Link>
                  </li>
                  <li class="nav-item dropdown ">
                    <Link
                      class="nav-link dropdown-toggle"
                      to="#"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Service We Offer <span class="fa fa-angle-down"></span>
                    </Link>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                      <Link class="dropdown-item" to="/Air">
                        Air Freight
                      </Link>
                      <Link class="dropdown-item" to="/Sea">
                        Sea Freight
                      </Link>
                      <Link class="dropdown-item" to="/Project">
                        Project Logistics
                      </Link>
                      <Link class="dropdown-item" to="/Supply">
                        Supply Chain Management
                      </Link>
                      <Link class="dropdown-item" to="/Customs">
                        Customs Brokerage & Compliance
                      </Link>
                      <Link class="dropdown-item" to="/Imports">
                        Imports & Exports
                      </Link><Link class="dropdown-item" to="/Trading">
                        Our Trading Services
                      </Link>
                    </div>
                  </li>
                  
                  <li className="nav-item @@contact__active">
                    <Link className="nav-link" to="/Contact">
                      Contact
                    </Link>
                  </li>
                </ul>
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item ml-4">
                    <Link className="nav-link phone" to="tel:{companynumber}">
                      <span className="fa fa-phone" /> {companynumber}
                    </Link>
                  </li>
                  <li className="nav-item" title="Search">
                  <Link className="action-button btn " to="/Getquote">Request For Quote</Link>
                  </li>
                 
                </ul>
              </div>
             
            </nav>
          </div>
        </header>
        {/*/header*/}
      </section>
    </>
  );
};

export default Header;
