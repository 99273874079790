import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />
      <section className="w3l-inner-banner-main">
          <div className="about-inner contact">
            <div className="breadcrumbs-sub">
              <ul className="breadcrumbs-custom-path">
                <li className="right-side propClone"><Link to="/Home" className="editContent">Home <span className="fa fa-angle-right" aria-hidden="true" /></Link> <p /></li>
                <li className="active editContent">
                  Air Feight</li>
              </ul>
            </div>
          </div>
        </section>
      <section className="w3l-blog-single">
        <div className="sec-padding editContent">
          <div className="container">
            <div className="blog-grid">
              <div className="blog-right">
                {/* latest posts */}
                 <div className="latest-posts blog-right-single">
                  <div className="comments-grid-right ">
                    <h5 className="editContent">Our Services</h5>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Air">
                        <img src="assets/images/air.jpg" className="img-responsive" alt="" /></Link>
                    </div>
                    <div className="single-blog-left">
                      
                      <h4><Link to="/Air" className="editContent">Air Freight</Link>
                      </h4>
                    </div>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Sea">
                        <img src="assets/images/ocean.jpg" className="img-responsive" alt="" /></Link>
                    </div>
                    <div className="single-blog-left">
                      
                      <h4><Link to="/Sea" className="editContent">Sea Freight</Link>
                      </h4>
                    </div>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Project">
                        <img src="assets/images/project.webp" className="img-responsive" alt="" /></Link>
                    </div>
                    <div className="single-blog-left">
                      
                      <h4><Link to="/Project" className="editContent">Project Logistics</Link>
                      </h4>
                    </div>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Supply">
                        <img src="assets/images/supply.webp" className="img-responsive" alt="" /></Link>
                    </div>
                    <div className="single-blog-left">
                      
                      <h4><Link to="/Supply" className="editContent">Supply Chain Management</Link>
                      </h4>
                    </div>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Customs">
                        <img src="assets/images/customs.jpg" className="img-responsive" alt="" /></Link>
                    </div>
                    <div className="single-blog-left">
                      
                      <h4><Link to="/Customs" className="editContent">Customs Brokerage & Compliance</Link>
                      </h4>
                    </div>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Imports">
                        <img src="assets/images/imports.jpg" className="img-responsive" alt="" /></Link>
                    </div>
                    <div className="single-blog-left">
                      
                      <h4><Link to="/Imports" className="editContent">Imports & Export</Link>
                      </h4>
                    </div>
                  </div>
                </div>
                
              </div>
              {/*blog-grid*/}
              <div className="blog-left blog-right-single">
                <div className="blog-grid-one">
                  <img src="assets/images/air.jpg" alt=" " className="img-responsive" />
                  <h4><Link className="editContent">  Air Freight
                    </Link></h4>
                  <p className="para editContent">
                  
                  Speed, reliability, and unwavering consistency are pivotal factors when contemplating the transportation of goods. In this realm, the choice often boils down to two potent alternatives: sea freight and air freight services. While both options bear their own merits, it is the realm of air freight that stands as the unparalleled champion in connecting the far reaches of the globe with expeditious finesse, condensing what used to be a matter of weeks via sea routes into mere days through the aerial corridors.
<br/>
For enterprises that thrive on swift turnarounds and prompt deliveries, the essence of time cannot be overstated. Within the Australian business landscape, our meticulously tailored air freight solutions emerge as the cornerstone of assurance, ensuring that your delivery obligations are not just met, but surpassed. This, in turn, empowers you to engage in meticulous premeditation, knowing that your logistical prowess is fortified by a network that understands the gravity of each commitment undertaken.
<br/>
In the dynamic theater of global commerce, agility in operations has emerged as the clarion call of the hour. It is the rapidity with which connections are established and the reliability with which consignments traverse continents that form the bedrock upon which successful ventures are erected. Air freight, as an embodiment of these principles, stands as an emblem of certainty amidst an ever-shifting milieu. It kindles the embers of anticipation, allowing businesses to not just navigate the intricacies of supply and demand but to orchestrate them with a symphonic cadence that resonates with triumph.
<br/>
Moreover, as markets intertwine and customer expectations ascend to unprecedented altitudes, the logistical framework underpinning modern businesses must rise to the occasion with unwavering resolve. The air freight solutions we offer pledge an unswerving allegiance to timeliness, thereby endowing enterprises with the leverage to transmute pledges into actions and strategies into triumphs. This allegiance is more than a mere commitment; it is a covenant that transcends the transactional and blossoms into a partnership, wherein your aspirations become our compass, guiding each shipment to the pinnacle of punctuality.
<br/>
In summation, the juxtaposition of time and logistics forms the nucleus around which the realm of business orbits. The choice between sea and air freight reverberates far beyond the surface, touching realms of commitment, strategy, and ultimate success. In this orchestration, our air freight solutions etch a testament to the supremacy of swift yet reliable conveyance, enabling Australian businesses to not only uphold their delivery promises but to embrace a future where their plans take flight on the wings of confidence.

                  </p>
                </div>
               
              </div>
            </div>
           
           
          </div>
        </div></section>
      <Footer />
    </>
  );
}
