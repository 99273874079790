import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />
      <section className="w3l-inner-banner-main">
        <div className="about-inner contact">
          <div className="breadcrumbs-sub">
            <ul className="breadcrumbs-custom-path">
              <li className="right-side propClone">
                <Link to="/Home" className="editContent">
                  Home <span className="fa fa-angle-right" aria-hidden="true" />
                </Link>{" "}
                <p />
              </li>
              <li className="active editContent">Trading Services</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-covers-18">
          <div className="covers-main editContent">
            <div className="container">
             
            </div>
          </div>
        </section>
      <section className="w3l-specification-6">
          <div className="specification-layout editContent">
            <div className="container-fluid">
            <div className="text-center m-4">
                  <h2>
                 Our Trading Services
                  </h2>
                </div>
                <div className="text-center m-4">
                  <p>
                  At the crossroads of international trade, {companyname} emerges as a
                distinguished trader and customs broker, a beacon guiding the
                seamless movement of goods across the global stage. With an
                expertise honed through time and a global network woven
                meticulously, we stand as custodians of cost-effective,
                efficient, and unwaveringly reliable solutions for our esteemed
                clientele.
                <br />
                Our expansive network of partners spans continents, a testament
                to our commitment to craft a world without borders, where goods
                traverse oceans and skies with a precision that echoes
                efficiency. This consortium of collaborations forms the bedrock
                upon which our promise of seamless solutions rests, ensuring
                that each customer journey is not just a transaction but a
                symphony of fulfillment.
                  </p>
                </div>
              
            </div>
          </div>
        </section>
        
      <section className="w3l-recent-work">
        <div className="jst-two-col">
          <div className="container">
            <div className="row">
              <div className="my-bio cwp4-text col-lg-6">
                <h3>FMCG</h3>
                <p className="para mt-3 mb-lg-4 "> The vibrant landscape of India's Fast-Moving Consumer Goods (FMCG) sector finds its expansive reach resonating across South Asian countries, the Middle East, and far-reaching corners of the globe. In this dynamic panorama, our role emerges as a pivotal enabler, ushering manufacturers, wholesalers, and producers towards the global stage, transforming their goods into international exports. With a fervent dedication to service, we facilitate the seamless journey of these products, bridging the gap between origins and destinations with finesse.
 </p>
               
              </div>
              <div className="col-lg-6 ">
                <img src="assets/images/fmcg.jpg" alt="product" className="img-responsive about-me" />
              </div>
            </div>
            
          </div>
          
        </div>
      </section>
      <section className="w3l-recent-work">
        <div className="jst-two-col">
          <div className="container">
            <div className="row">
            <div className="col-lg-6 ">
                <img src="assets/images/wood.jpg" alt="product" className="img-responsive about-me" />
              </div>
              <div className="my-bio cwp4-text col-lg-6">
                <h3>Wood</h3>
                <p className="para mt-3 mb-lg-4 ">India shines as a notable exporter, particularly in the realm of wood products. The art of wood trading unfurls a vast spectrum of offerings tailored to diverse market sectors. This trade hinges upon a profound understanding of the market intricacies, allowing for the creation of finished products that boast unparalleled quality, finely attuned to the demands of their intended market. Our organization stands as a seasoned player within this landscape, specializing in the trading of logs, lumber, and woodchips sourced from lush forests.

Our expertise transcends the mere transaction; it encompasses the entire tapestry of the trade process, from the initial harvest to the ultimate supply to end-users.  </p>
               
              </div>
             
            </div>
            
          </div>
          
        </div>
      </section>
      
      <section className="w3l-recent-work">
        <div className="jst-two-col">
          <div className="container">
            <div className="row">
              <div className="my-bio cwp4-text col-lg-6">
                <h3>Pharma</h3>
                <p className="para mt-3 mb-lg-4 "> Within the expansive landscape of India's pharmaceutical domain, a robust and well-established market thrives, providing a hospitable home to a multitude of international enterprises. While accessibility to a diverse array of products remains unhindered, the pricing paradigm retains its sensibility. This confluence of factors serves as a testament to the considerable presence and viability of the Indian pharmaceutical market. It is within this realm that we emerge as facilitators, offering a gateway to procure high-quality pharmaceutical items from internationally acclaimed businesses.<br/>
 </p>
               
              </div>
              <div className="col-lg-6 ">
                <img src="assets/images/pharma.jpg" alt="product" className="img-responsive about-me" />
              </div>
            </div>
            
          </div>
          
        </div>
      </section>
      <section className="w3l-recent-work">
        <div className="jst-two-col">
          <div className="container">
            <div className="row">
            <div className="col-lg-6 ">
                <img src="assets/images/apparel.jpg" alt="product" className="img-responsive about-me" />
              </div>
              <div className="my-bio cwp4-text col-lg-6">
                <h3>Apparel</h3>
                <p className="para mt-3 mb-lg-4 "> 
Nestled at the heart of cotton and textile production, India stands as a prolific source of raw materials that cascade into the creation of apparel. In this dynamic landscape, we step forth as enablers, dedicated to empowering mill owners, producers, and wholesalers in navigating the global trade of these textile treasures. Our mission reverberates as we facilitate the seamless import and export of these essentials to every corner of the world.

At our core, we assume the role of custodians, overseeing the intricate global distribution web of textiles, apparel, and accessories. </p>
               
              </div>
             
            </div>
            
          </div>
          
        </div>
      </section>
      <Footer />
    </>
  );
}
