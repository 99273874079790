import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  return (
    <>
      {" "}
      <section className="w3l-footer-29-main">
        <div className="footer-29 py-5 ">
          <div className="container">
            <div className="grid-col-4 footer-top-29">
              <div className="footer-list-29 footer-1">
              <img
                    src="assets/images/logo.png "
                    style={{ height: "70px" }}
                  ></img>
                <ul>
                  <li>
                 <p>We stand out as distinguished Freight forwarding Service Providers, originating from India. Our unwavering commitment and reliable solutions have propelled us to a prestigious standing. Utilizing our extensive and well-established network, we offer seamless services including Air and Sea Freight Forwarding, Warehousing Storage, and Customs Clearing
                 </p> 
                  </li>
                  
                </ul>
              </div>
              <div className="footer-list-29 footer-2">
                <ul>
                  <h6 className="footer-title-29">Useful Links</h6>
                  <li>
                    <Link to="/Home">Home</Link>
                  </li>
                  <li>
                    <Link to="/About">About</Link>
                  </li>
                  <li>
                    <Link to="/Services">Services</Link>
                  </li>
                  <li>
                    <Link to="/Contact">Contact</Link>
                  </li>
                  <li>
                    <Link to="/Getquote">Getquote</Link>
                  </li>
                  <li>
                    <Link to="/Privacy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/Terms">Terms</Link>
                  </li>
                </ul>
              </div>
              <div className="footer-list-29 footer-1">
                <h2 className="footer-title-29">Contact Us</h2>
                <ul>
                  <li>
                    <p>
                      <span className="fa fa-map-marker" />
                      {companyaddress}
                    </p>
                  </li>
                  <li>
                    <Link to="tel:{companynumber}">
                      <span className="fa fa-phone" /> {companynumber}
                    </Link>
                  </li>
                  <li>
                    <Link to="mailto: {companyemail}" className="mail">
                      <span className="fa fa-envelope-open-o" />
                      {companyemail}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="footer-list-29 footer-3">
                <ul>
                  <h6 className="footer-title-29">Our Services</h6>
                  <li><Link to="/Air">Air Freight</Link></li>
                  <li><Link to="/Sea">Sea Freight</Link></li>
                  <li><Link to="/Project">Project Logistics</Link></li>
                  <li><Link to="/Supply">Supply Chain Management</Link></li>
                  <li><Link to="/Customs">Customs Brokerage & Compliance</Link></li>
                  <li><Link to="/Imports">Imports & Exports</Link></li>
                  <li> <Link to="/Trading">Our Trading Services</Link></li>
                </ul>
              </div>
             
            </div>
            <div className=" bottom-copies text-center">
              <p className="copy-footer-29">
                © 2023 {companyname}. All Rights Reserved.
                
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
