import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />
      <section className="w3l-inner-banner-main">
        <div className="about-inner contact">
          <div className="breadcrumbs-sub">
            <ul className="breadcrumbs-custom-path">
              <li className="right-side propClone">
                <Link to="/Home" className="editContent">
                  Home <span className="fa fa-angle-right" aria-hidden="true" />
                </Link>{" "}
                <p />
              </li>
              <li className="active editContent">
              Imports & Export
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-blog-single">
        <div className="sec-padding editContent">
          <div className="container">
            <div className="blog-grid">
              <div className="blog-right">
                {/* latest posts */}
                <div className="latest-posts blog-right-single">
                  <div className="comments-grid-right ">
                    <h5 className="editContent">Our Services</h5>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Air">
                        <img
                          src="assets/images/air.jpg"
                          className="img-responsive"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="single-blog-left">
                      <h4>
                        <Link to="/Air" className="editContent">
                          Air Freight
                        </Link>
                      </h4>
                    </div>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Sea">
                        <img
                          src="assets/images/ocean.jpg"
                          className="img-responsive"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="single-blog-left">
                      <h4>
                        <Link to="/Sea" className="editContent">
                          Sea Freight
                        </Link>
                      </h4>
                    </div>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Project">
                        <img
                          src="assets/images/project.webp"
                          className="img-responsive"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="single-blog-left">
                      <h4>
                        <Link to="/Project" className="editContent">
                          Project Logistics
                        </Link>
                      </h4>
                    </div>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Supply">
                        <img
                          src="assets/images/supply.webp"
                          className="img-responsive"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="single-blog-left">
                      <h4>
                        <Link to="/Supply" className="editContent">
                          Supply Chain Management
                        </Link>
                      </h4>
                    </div>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Customs">
                        <img
                          src="assets/images/customs.jpg"
                          className="img-responsive"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="single-blog-left">
                      <h4>
                        <Link to="/Customs" className="editContent">
                          Customs Brokerage & Compliance
                        </Link>
                      </h4>
                    </div>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Imports">
                        <img
                          src="assets/images/imports.jpg"
                          className="img-responsive"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="single-blog-left">
                      <h4>
                        <Link to="/Imports" className="editContent">
                          Imports & Export
                        </Link>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              {/*blog-grid*/}
              <div className="blog-left blog-right-single">
                <div className="blog-grid-one">
                  <img
                    src="assets/images/imports.jpg"
                    alt=" "
                    className="img-responsive"
                  />
                  <h4>
                    <Link className="editContent">
                      {" "}
                      Imports & Export
                    </Link>
                  </h4>
                  <p className="para editContent">
                    Irrespective of your merchandise's nature or its global
                    destination, {companyname} is your unwavering partner in
                    ensuring the secure and seamless handling of your imported
                    cargo. Our commitment extends to effortless and hassle-free
                    importation of goods from every corner of the world. In our
                    role as an import-export broker, we go beyond the ordinary,
                    equipping you with a comprehensive suite of logistics
                    solutions and expert consultation, all aimed at streamlining
                    your process to perfection.
                    <br />
                    With a steadfast dedication to precision, safety, and
                    efficiency, we navigate the intricacies of global trade,
                    ensuring that your valuable cargo is delivered with utmost
                    care and timeliness. Whether your goods are sourced from
                    bustling markets or remote locales, our extensive network
                    and meticulous approach mean that your shipments are in
                    capable hands.
                    <br />
                    At {companyname}, we are more than just logistics; we are
                    your trusted partner, simplifying the complex journey of
                    importing. We understand that the import-export landscape is
                    rife with challenges and intricacies, which is why we stand
                    by your side, providing you not only with seamless
                    logistical solutions but also with the guidance needed to
                    make your import process as smooth as a well-charted voyage.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
