import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />
      <section className="w3l-inner-banner-main">
        <div className="about-inner contact">
          <div className="breadcrumbs-sub">
            <ul className="breadcrumbs-custom-path">
              <li className="right-side propClone">
                <Link to="/Home" className="editContent">
                  Home <span className="fa fa-angle-right" aria-hidden="true" />
                </Link>{" "}
                <p />
              </li>
              <li className="active editContent">Customs Brokerage & Compliance</li>
            </ul>
          </div>
        </div>
      </section>
      <section className="w3l-blog-single">
        <div className="sec-padding editContent">
          <div className="container">
            <div className="blog-grid">
              <div className="blog-right">
                {/* latest posts */}
                <div className="latest-posts blog-right-single">
                  <div className="comments-grid-right ">
                    <h5 className="editContent">Our Services</h5>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Air">
                        <img
                          src="assets/images/air.jpg"
                          className="img-responsive"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="single-blog-left">
                      <h4>
                        <Link to="/Air" className="editContent">
                          Air Freight
                        </Link>
                      </h4>
                    </div>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Sea">
                        <img
                          src="assets/images/ocean.jpg"
                          className="img-responsive"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="single-blog-left">
                      <h4>
                        <Link to="/Sea" className="editContent">
                          Sea Freight
                        </Link>
                      </h4>
                    </div>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Project">
                        <img
                          src="assets/images/project.webp"
                          className="img-responsive"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="single-blog-left">
                      <h4>
                        <Link to="/Project" className="editContent">
                          Project Logistics
                        </Link>
                      </h4>
                    </div>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Supply">
                        <img
                          src="assets/images/supply.webp"
                          className="img-responsive"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="single-blog-left">
                      <h4>
                        <Link to="/Supply" className="editContent">
                          Supply Chain Management
                        </Link>
                      </h4>
                    </div>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Customs">
                        <img
                          src="assets/images/customs.jpg"
                          className="img-responsive"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="single-blog-left">
                      <h4>
                        <Link to="/Customs" className="editContent">
                          Customs Brokerage & Compliance
                        </Link>
                      </h4>
                    </div>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Imports">
                        <img
                          src="assets/images/imports.jpg"
                          className="img-responsive"
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="single-blog-left">
                      <h4>
                        <Link to="/Imports" className="editContent">
                          Imports & Export
                        </Link>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              {/*blog-grid*/}
              <div className="blog-left blog-right-single">
                <div className="blog-grid-one">
                  <img
                    src="assets/images/customs.jpg"
                    alt=" "
                    className="img-responsive"
                  />
                  <h4>
                    <Link className="editContent"> Customs Brokerage & Compliance</Link>
                  </h4>
                  <p className="para editContent">
                    {companyname} takes immense pride in the prowess of its
                    customs broker team, a cadre of professionals meticulously
                    honed and meticulously licensed to deftly navigate the
                    intricate landscape of customs and quarantine mandates. This
                    select group of experts boasts an impressive track record of
                    engagement with a diverse spectrum of stakeholders,
                    including importers, exporters, port authorities, and even
                    governmental bodies. It is their seasoned proficiency that
                    renders them your quintessential collaborators in
                    facilitating the seamless traversal of your cargo across
                    borders, ushering it with finesse from entry points to
                    storage domains.
                    <br />
                    At {companyname}, the mantle of customs clearance isn't
                    merely a procedural undertaking; it is an art form executed
                    with precision and skill. Armed with an intricate
                    understanding of the ever-evolving regulatory tapestry, our
                    customs brokers emerge as veritable maestros orchestrating a
                    harmonious symphony between compliance and logistical
                    fluidity. They deftly untangle the complexities of customs
                    and quarantine requirements, transforming potential
                    bottlenecks into pathways of uninterrupted progression.
                    <br />
                    The import and export realm is rife with nuances,
                    necessitating a comprehensive comprehension of its
                    mechanics. Herein lies the competitive edge of {companyname}
                    's customs broker team, which has accrued a wealth of
                    experiential wisdom through its constant interactions with a
                    myriad of industry stakeholders. Whether your cargo's voyage
                    is inbound or outbound, it is their proficiency that paves
                    the way, allowing you to navigate the intricate customs
                    currents with confidence and ease.
                    <br />
                    In essence, {companyname}'s customs broker team stands not
                    only as facilitators of passage but as architects of a
                    seamless transition. Their licenses attest to their
                    competence, and their history of engagement speaks volumes
                    of their dedication. As you tread the path of international
                    trade, these experts stand ready, armed with knowledge,
                    experience, and an unwavering commitment to ensure that your
                    cargo's journey transcends borders, finding its rightful
                    abode in the realm of storage, all the while exemplifying
                    the ethos that {companyname} Logistics so ardently upholds.
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
