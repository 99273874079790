import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />
      <div className="inner-banner py-5">
        <section className="w3l-breadcrumb text-left py-sm-5 ">
          <div className="container">
            <div className="w3breadcrumb-gids">
              <div className="w3breadcrumb-left text-left">
                <h2 className="inner-w3-title">Road Transport </h2>
                <p className="inner-page-para mt-2"></p>
              </div>
              <div className="w3breadcrumb-right">
                <ul className="breadcrumbs-custom-path">
                  <li>
                    <Link href="/Home">Home</Link>
                  </li>
                  <li className="active">
                    <span className="fas fa-angle-double-right mx-2" /> Road
                    Transport
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="w3l-services-1 py-5" id="grids-3">
        <div className="container py-md-5 py-2">
          {/*/row-1*/}
          <div className="row mb-5 pb-lg-4">
            <div className="col-lg-6 p5-lg-4 services-1-left">
              <img
                src="assets/images/road.jpg"
                alt=""
                className="img-fluid radius-image"
              />
            </div>
            <div className="services-1-right col-lg-6 mt-lg-0 mt-md-5 mt-4 ps-lg-5">
              <div className="title-content text-left">
                <h6 className="title-subw3hny mb-1">Services </h6>
                <h3 className="title-w3l mb-3">Road Transport </h3>
              </div>
              <p className>
                For businesses engaged in domestic product transportation, road
                freight services offer a crucial advantage. {companyname}{" "}
                understands the unique challenges faced by businesses in this
                domain and stands ready to deliver innovative solutions through
                our comprehensive road freight services.
                <br />
                Despite the diversification of freight transport options over
                the years, it is worth noting that a staggering 80% of all
                domestic cargo still relies on road transportation. This
                statistic highlights the continued significance and reliability
                of road freight services in the India.
                <br />
               
              </p>
            </div>
          </div>
          {/*//row-1*/}
          <p className> At {companyname}, we pride ourselves on being attentive
                listeners, dedicated to understanding your specific challenges
                and requirements. Our expert team is equipped to devise tailored
                solutions that address your unique transportation needs,
                ensuring your cargo reaches its destination seamlessly and
                efficiently.
                <br />
                By leveraging our road freight services, you can streamline your
                logistics operations and optimize supply chain efficiency. With
                our commitment to reliability and timely deliveries, you can
                focus on growing your business with the confidence that your
                products are in safe hands.
                <br />
                Our road freight services provide the flexibility and
                cost-effectiveness needed to meet your transportation goals
                while ensuring your cargo's safety and security throughout the
                journey.
                <br />
                At {companyname}, we prioritize client satisfaction and aim to
                build lasting partnerships. By choosing us as your road freight
                partner, you gain access to a reliable and dependable service
                that contributes to the success and growth of your business.
                <br />
                In conclusion, road freight services remain a vital component of
                domestic cargo transportation, and {companyname} is your trusted
                partner in delivering innovative and efficient solutions.
                Embrace the advantages of professional shipping companies like
                ours to unlock the full potential of your business and stay
                ahead in the ever-evolving marketplace.</p>
        </div>
      </div>
      <Footer />
    </>
  );
}
