import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />
      <section className="w3l-inner-banner-main">
          <div className="about-inner contact">
            <div className="breadcrumbs-sub">
              <ul className="breadcrumbs-custom-path">
                <li className="right-side propClone"><Link to="/Home" className="editContent">Home <span className="fa fa-angle-right" aria-hidden="true" /></Link> <p /></li>
                <li className="active editContent">
                Supply Chain Management</li>
              </ul>
            </div>
          </div>
        </section>
      <section className="w3l-blog-single">
        <div className="sec-padding editContent">
          <div className="container">
            <div className="blog-grid">
              <div className="blog-right">
                {/* latest posts */}
                 <div className="latest-posts blog-right-single">
                  <div className="comments-grid-right ">
                    <h5 className="editContent">Our Services</h5>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Air">
                        <img src="assets/images/air.jpg" className="img-responsive" alt="" /></Link>
                    </div>
                    <div className="single-blog-left">
                      
                      <h4><Link to="/Air" className="editContent">Air Freight</Link>
                      </h4>
                    </div>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Sea">
                        <img src="assets/images/ocean.jpg" className="img-responsive" alt="" /></Link>
                    </div>
                    <div className="single-blog-left">
                      
                      <h4><Link to="/Sea" className="editContent">Sea Freight</Link>
                      </h4>
                    </div>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Project">
                        <img src="assets/images/project.webp" className="img-responsive" alt="" /></Link>
                    </div>
                    <div className="single-blog-left">
                      
                      <h4><Link to="/Project" className="editContent">Project Logistics</Link>
                      </h4>
                    </div>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Supply">
                        <img src="assets/images/supply.webp" className="img-responsive" alt="" /></Link>
                    </div>
                    <div className="single-blog-left">
                      
                      <h4><Link to="/Supply" className="editContent">Supply Chain Management</Link>
                      </h4>
                    </div>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Customs">
                        <img src="assets/images/customs.jpg" className="img-responsive" alt="" /></Link>
                    </div>
                    <div className="single-blog-left">
                      
                      <h4><Link to="/Customs" className="editContent">Customs Brokerage & Compliance</Link>
                      </h4>
                    </div>
                  </div>
                  <div className="blog-right-side-post editContent">
                    <div className=" single-blog-image">
                      <Link to="/Imports">
                        <img src="assets/images/imports.jpg" className="img-responsive" alt="" /></Link>
                    </div>
                    <div className="single-blog-left">
                      
                      <h4><Link to="/Imports" className="editContent">Imports & Export</Link>
                      </h4>
                    </div>
                  </div>
                </div>
                
              </div>
              {/*blog-grid*/}
              <div className="blog-left blog-right-single">
                <div className="blog-grid-one">
                  <img src="assets/images/supply.webp" alt=" " className="img-responsive" />
                  <h4><Link className="editContent"> Supply Chain Management
                    </Link></h4>
                  <p className="para editContent">
                  The orchestration of Supply Chain Management transcends conventional boundaries, weaving together a tapestry of pivotal business processes that converge to craft tangible value for both customers and stakeholders. In this intricate web, {companyname} excels, specializing in the intricate domain of freight forwarding across diverse industries, ranging from retail, fashion & textiles, to medical and construction.
<br/>
The canvas of the supply chain extends far beyond the confines of any singular entity, encompassing a spectrum of intricate intricacies that span across sourcing, supplier management, production, logistics, distribution, communication, transparency, and the ever-evolving realm of information technology. This holistic approach signifies that the supply chain functions as a dynamic ecosystem, each element intricately linked to the others, harmonizing their efforts to form a symphony of efficiency and collaboration. An indispensable facet is the harmonious coordination and collaboration with an array of channel partners, including suppliers, intermediaries, third-party service providers, and the ultimate end-users – the customers.
<br/>
The twin beacons guiding the compass of any prudent supply chain logistics strategy are rooted in the ideals of efficiency and value augmentation. This imperative is the heartbeat of {companyname}'s approach. Our supply chain strategies, meticulously fashioned, bear the hallmark of a systems view, wherein each link, no matter how subtle, synergistically harmonizes with the others. The outcome is a finely-tuned mechanism, finely calibrated to engender customer value and unswerving satisfaction, thus serving as the true litmus test of the efficacy of the entire system.
<br/>
In this grand symposium of commerce, the crescendo is the felicity of your customers. Their contentment becomes the ultimate benchmark of triumph, a testament to the efficacy of the intricate supply chain dance. {companyname} doesn't merely navigate the realms of supply and demand; we navigate the very currents of customer delight, ensuring that the interplay of processes isn't merely efficient, but resonates with the symphony of customer-centricity, thereby etching an indelible imprint of success in the annals of supply chain management.
<br/>
                  
                  </p>
                </div>
               
              </div>
            </div>
           
           
          </div>
        </div></section>
      <Footer />
    </>
  );
}
